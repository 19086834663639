<template>
  <div class="detailListBox">
    <!-- 搜索商品弹窗 start-->
    <el-dialog
      class="detailListDiolag"
      title="明细列表"
      :visible.sync="detailListData.detailListDialogFlag"
      :close-on-click-modal="false"
    >
    <ul  class="nav">
        <li :class="ActiveIndex==0?'active':''" @click="ListFun(0)">订单明细</li>
        <li :class="ActiveIndex==1?'active':''" @click="ListFun(1)">还款明细</li>
    </ul>
     <el-table class="tableBox" border  :data="ListData"  v-show="OrderFlag">
        <el-table-column   type="index"  label="序号"  width="50" align="center"></el-table-column>
        <el-table-column prop="sn" label="订单编号" width="200" align="center"></el-table-column>
        <el-table-column prop="payableAmount" label="订单金额（元）" width="120"  align="center"></el-table-column>
        <el-table-column prop="createTime" label="下单时间" width="180"  align="center"></el-table-column>
        <el-table-column  label="订单状态" align="center">
             <template slot-scope="scope">
                 <!-- 订单状态（1：待支付，2待发货，3待收货，4已完成，5已取消,6飞贷付款） -->
                    <span v-if="scope.row.status==1">待支付</span>
                    <span v-else-if="scope.row.status==2">待发货 </span>
                    <span v-else-if="scope.row.status==3">待收货 </span>
                    <span v-else-if="scope.row.status==4">已完成 </span>
                    <span v-else-if="scope.row.status==5">已取消 </span>
                    <span v-else>飞贷付款</span>
                </template>
        </el-table-column>
      </el-table>
      <el-table class="tableBox" border  :data="ListData"  v-show="!OrderFlag">
        <el-table-column   type="index"  label="序号"  width="80" align="center"></el-table-column>
        <el-table-column prop="repayAmount" label="还款金额" width="200" align="center"></el-table-column>
        <el-table-column prop="payableAmount" label="还款方式" width="160"  align="center">
                <template slot-scope="scope">
                <!-- 还款类型 0：微信还款 1：支付宝还款 2：线下还款 3：结余还款 -->
                    <span v-if="scope.row.repayType==0">微信还款</span>
                    <span v-else-if="scope.row.repayType==1">支付宝还款 </span>
                    <span v-else-if="scope.row.repayType==2">线下还款 </span>
                    <span v-else>结余还款</span>
                </template>
        </el-table-column>
        <el-table-column prop="createTime" label="还款日期"   align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 搜索商品弹窗 end -->
  </div>
</template>
<script>
import {
  getSettlementOrderDetail, //查询结算单订单明细列表
  getSettlementOrderRepay,//查询结算单还款列表
} from "api/creditPay.js";
export default {
  name: "detailListDiolag",
  data() {
    return {
      ActiveIndex:0,
      total: 0,
      ListData:[],
      OrderFlag:true
    };
  },
  props: {
    detailListData: {
      type: Object,
    },
  },
  watch: {
    detailListData: function(newValue) {
      if (newValue) {
        this.GetOrderListFun();
        this.ActiveIndex=0,
        this.OrderFlag=true
      }
    },
  },
  methods: {
      ListFun(key){
          this.ActiveIndex=key
          if(key==0){
            //订单明细
            this.OrderFlag=true
             this.GetOrderListFun();
          }else{
            //还款明细
            this.OrderFlag=false
            this.GetOrderRepayListFun()
          }
      },
      // 还款明细列表
    GetOrderRepayListFun() {
      // 查询结算单还款明细列表
      getSettlementOrderRepay(this.detailListData.id).then((res) => {
        console.log("还款明细;", res.data.data);
        this.total = res.data.data.length;
        this.ListData=res.data.data
      });
    },
    // 订单明细列表
    GetOrderListFun() {
      // 查询结算单订单明细列表
      getSettlementOrderDetail(this.detailListData.id).then((res) => {
        console.log("订单明细;", res.data.data);
        this.total = res.data.data.length;
        this.ListData=res.data.data
      });
    },
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>

  .nav {
    width: 100%;
    display: block;
    height: 30px;
    li {
        float: left;
        padding-right:15px;
        font-size: 14px;
    }
    .active {
        color: #FF3333;
        font-weight: bold;
      }
  }
.detailListBox {
  width: 450px;
  float: right;
 
}
.detailListDiolag {
  /deep/.el-dialog__header {
    padding: 10px 20px;
    background: rgba(240, 240, 240, 1);
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    font-weight: bold;
    .el-dialog__title{
        font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: 10px;
      font-size:22px;
    }
  }
   .tableBox {
    width: 100%;
    margin-top:20px;
    max-height: 300px;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-shrink: 0;
    background: #f9f9f9;
    padding-bottom:1px;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
    
}
</style>
